import Vue from 'vue'
import Vuex from 'vuex'

import catalogos from './catalogos.js'
import http from './http/calls.js'
import security from './security.js'
import trabajadores from './trabajadores.js'

Vue.use(Vuex)

export default new Vuex.Store({
    modules:{
        catalogos,
        http,
        security,
        trabajadores,
    }
})
