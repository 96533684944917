<template>
    <v-app>
        <div
        :style='{
                "min-width": "100%",
                "min-height": "100%",
                "background-image":"url("+$url+"/images/logo-sntss-bg.png"+")",
                "background-repeat":"repeat"
            }'
        >
            <encabezado-main v-if="$store.state.security.username"/>
            <login-window/>
            <v-main>
                <transition name="fade" mode="out-in">
                    <router-view />
                </transition>
            </v-main>
        </div>
    </v-app>
</template>

<script>

export default {
    name: 'App',
    components:{
        'encabezado-main':()=>import('@/views/layout/EncabezadoMain'),
        'login-window':()=>import('@/components/security/LoginWindow'),
    },
    data: () => ({
        //
    }),
};
</script>

<style>
.fade-enter-active, .fade-leave-active {
    transition: opacity .25s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
</style>
